
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vuexy-primary: mat.define-palette(mat.$indigo-palette);
$vuexy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$vuexy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vuexy-theme: mat.define-light-theme((
  color: (
    primary: $vuexy-primary,
    accent: $vuexy-accent,
    warn: $vuexy-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vuexy-theme);

@import '@core/scss/core.scss';
@import './assets/scss/styles';

html, body { height: 100%; }
body { margin: 0; font-family: "Montserrat", Helvetica, Arial, sans-serif; }

// add ng-select theme
@import '@core/scss/angular/libs/select.component.scss';
// add data talbe theme
@import '@core/scss/angular/libs/datatables.component.scss';
// add chart style
@import '@core/scss/base/plugins/charts/chart-apex.scss';
@import '@core/scss/angular/libs/flatpickr.component.scss';
// calendar form
@import '@core/scss/angular/libs/date-time-picker.component.scss';
//tree-root
@import '@core/scss/angular/libs/tree-view.component.scss';


.dark-layout .machine-item:hover {
	background-color: #242b3d !important;
}
.pac-container {
  z-index: 9999999 !important;
}

.ngx-datatable-value-max {
  color: red !important;
  background-color: gainsboro;
}

.flatpickr-calendar {
  z-index: 9999999 !important;
}